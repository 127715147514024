import React, { useState, useEffect } from "react";
import "./ReferenceListData.scss";
import locationIcon from "../ReferenceListImages/location-icon.svg";
import leftArrow from "../ReferenceListImages/left-arrow.svg";
import axios from "axios";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import propTypes from "prop-types";
import { FixedSizeList as List } from "react-window";
import useViewport from "../../helpers/useViewport";
import errorIcon from "../../ExpiredLink/ExpiredImages/expired.svg";
import NoResultsFound from "../../shared/images/no-results-found.svg";
import FadeLoader from "react-spinners/FadeLoader";

export const ReferenceListData = ({
  searchTerm,
  filters,
  applyFilter,
  setApplyFilter,
  useFilteredData,
}) => {
  const {width, height, isMobile} = useViewport();
  const navigate = useNavigate();
  const location = useLocation();
  const SRPSessionID = location.state.SRPSessionID;
  const Product = location.state.Product;
  console.log('SRPSessionID in reference list data: ', SRPSessionID);
  // console.log({ location, SRPSessionID });
  const [referenceData, setReferenceData] = useState([]); //original array of data
  const [searchData, setSearchData] = useState([]); //array of searchData
  const [isSearching, setIsSearching] = useState(false); //boolean to show if searching or not
  const [filteredData, setFilteredData] = useState([]); //array of filtered data
  const [isLoading, setIsLoading] = useState(true);
  const [badResponse, setBadResponse] = useState(false);

  useEffect(() => {
    async function checkSessionId() {
      const decisionsEndpoint = global.location.href.includes(
        "references.appliedmedical.com"
      )
        ? "https://bpm.appliedmedical.com/Primary/?FlowAlias=SRPSessionService"
        : "https://bpmqa.appliedmedical.com/Primary/?FlowAlias=SRPSessionService";
      // console.log({ decisionsEndpoint });
      // console.log(SRPSessionID);
      let response = await axios(decisionsEndpoint, {
        params: {
          sessionid: "NS-2534cfef-2859-11ec-9166-005056bf3f1b",
          outputtype: "Json",
          SRPSessionID: SRPSessionID,
          Action: "api",
        },
      })
        .then((response) => {
          console.log({ response });
          console.log("SESSION ID IS VALID");
        })
        .catch((err) => {
          navigate(`/${SRPSessionID}/expired`);
          console.log("SESSION IS NOT VALID");
        });
    }
    checkSessionId();
  }, []);

  /**
   * will be used to query reference list data at mounting
   */
  useEffect(() => {
    //post api endpoint: https://decisionsdev2.appliedmed.com/Primary/?FlowAlias=SRPReferenceList&Action=api
    // setFilteredData(array);
    // setReferenceData(array);
    async function fetchSurgeonData() {
      const decisionsEndpoint = global.location.href.includes(
        "references.appliedmedical.com"
      )
        ? "https://bpm.appliedmedical.com/Primary/?FlowAlias=SRPReferenceList"
        : "https://bpmqa.appliedmedical.com/Primary/?FlowAlias=SRPReferenceList";
      // console.log({ decisionsEndpoint });
      let response = await axios(decisionsEndpoint, {
        params: {
          sessionid: "NS-2534cfef-2859-11ec-9166-005056bf3f1b",
          outputtype: "Json",
          SRPSessionID: SRPSessionID,
          Action: "api",
        },
      })
        .then((response) => {
          console.log("response: ", response);
          let data = [...response.data.Done.ReferenceData];
          console.log({ data });
          const orderBySpecialty = (a, b) => {
            if (a.Specialty.toLowerCase() < b.Specialty.toLowerCase()) {
              return -1;
            } else if (a.Specialty.toLowerCase() > b.Specialty.toLowerCase()) {
              return 1;
            } else {
              return 0;
            }
          };
          data.sort(orderBySpecialty);
          console.log("data sorted: ", data);
          setFilteredData(data);
          setReferenceData(data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          setBadResponse(true);
        });
    }
    fetchSurgeonData();
  }, []);

  /**
   * sets filtered data hook state
   */
  useEffect(() => {
    if (applyFilter === true) {
      let newArray = referenceData.filter(matchFilters);
      setFilteredData(newArray);
      setApplyFilter(false);
    }
  }, [applyFilter]);

  /**
   * searchTerm is used to filter reference data
   */
  useEffect(() => {
    if (searchTerm !== "") {
      setIsSearching(true);
      if (useFilteredData === true) {
        let newArray = filteredData.filter(matchSearchTerm);
        setSearchData(newArray);
      } else {
        let newArray = referenceData.filter(matchSearchTerm);
        setSearchData(newArray);
      }
    } else {
      setIsSearching(false);
    }
  }, [searchTerm, filteredData, useFilteredData]);

  /**
   * used to determine whether an element in the array matches the search term or not
   * @param {*} value element in an array
   * @returns true or false depending on if the element.name includes the search term or not
   */
  const matchSearchTerm = (value) => {
    return (
      value.SurgeonName.toLowerCase().includes(searchTerm) ||
      value.Specialty.toLowerCase().includes(searchTerm) ||
      value.Hospital.toLowerCase().includes(searchTerm)
    );
  };

  /**
   *
   * @param {*} value element from array thats being filtered
   * @returns true or false if element passes test or not
   */
  const matchFilters = (value) => {
    if (filters.length < 1) {
      return true;
    } else {
      return filters.find((el) => {
        return value.Specialty.toLowerCase().includes(el.toLowerCase());
      });
    }
  };

  /**
   * surgeon dummy data
   */
  const array = [
    {
      SurgeonName: "Random Surgeon Guy MD",
      Hospital: "Orange County Global Medical Center",
      Bio: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas ab explicabo dolorem cum ea ducimus veritatis quasi laboriosam? Placeat ad consectetur praesentium, aliquam ipsum perspiciatis velit amet quidem doloremque quam!",
      Specialty: "Thoracic Surgery",
      Email: "randomsurgery@gmail.com",
      Phone: "5361463534",
      PreferredContactMethod: "phone",
      image: "image",
    },
    {
      SurgeonName: "Dummy Data",
      Hospital: "Rancho Santa Margarita",
      Bio: "I am going to do it. I have made up my mind. These are the first few words of the new… the best … the Longest Text In The Entire History Of The Known Universe! This Has To Have Over 35,000 words the beat the current world record set by that person who made that flaming chicken handbooky thingy. I might just be saying random things the whole time I type in this so you might get confused a lot. I just discovered something terrible. autocorrect is on!! no!!! this has to be crazy, so I will have to break all the English language rules and the basic knowledge of the average human being. I am not an average human being, however I am special. no no no, not THAT kind of special ;). Why do people send that wink face! it always gives me nightmares! it can make a completely normal sentence creepy. imagine you are going to a friend’s house",
      Specialty: "Urology Surgery",
      Email: "coolsurgeon@yahoo.com",
      Phone: "1234567890",
      PreferredContactMethod: "email",
      image: "image",
    },
    {
      SurgeonName: "Dummy Data2",
      Hospital: "Rancho Santa Margarita",
      Bio: "I am going to do it. I have made up my mind. These are the first few words of the new… the best … the Longest Text In The Entire History Of The Known Universe! This Has To Have Over 35,000 words the beat the current world record set by that person who made that flaming chicken handbooky thingy. I might just be saying random things the whole time I type in this so you might get confused a lot. I just discovered something terrible. autocorrect is on!! no!!! this has to be crazy, so I will have to break all the English language rules and the basic knowledge of the average human being. I am not an average human being, however I am special. no no no, not THAT kind of special ;). Why do people send that wink face! it always gives me nightmares! it can make a completely normal sentence creepy. imagine you are going to a friend’s house",
      Specialty: "Urology Surgery",
      Email: "coolsurgeon@yahoo.com",
      Phone: "1234567890",
      PreferredContactMethod: "email",
      image: "image",
    },
    {
      SurgeonName: "Dummy Data3",
      Hospital: "Rancho Santa Margarita",
      Bio: "I am going to do it. I have made up my mind. These are the first few words of the new… the best … the Longest Text In The Entire History Of The Known Universe! This Has To Have Over 35,000 words the beat the current world record set by that person who made that flaming chicken handbooky thingy. I might just be saying random things the whole time I type in this so you might get confused a lot. I just discovered something terrible. autocorrect is on!! no!!! this has to be crazy, so I will have to break all the English language rules and the basic knowledge of the average human being. I am not an average human being, however I am special. no no no, not THAT kind of special ;). Why do people send that wink face! it always gives me nightmares! it can make a completely normal sentence creepy. imagine you are going to a friend’s house",
      Specialty: "Oncology Surgery",
      Email: "coolsurgeon@yahoo.com",
      Phone: "1234567890",
      PreferredContactMethod: "email",
      image: "image",
    },
    {
      SurgeonName: "Ryan Valdriz",
      Hospital: "Rancho Santa Margarita",
      Bio: "I am going to do it. I have made up my mind. These are the first few words of the new… the best … the Longest Text In The Entire History Of The Known Universe! This Has To Have Over 35,000 words the beat the current world record set by that person who made that flaming chicken handbooky thingy. I might just be saying random things the whole time I type in this so you might get confused a lot. I just discovered something terrible. autocorrect is on!! no!!! this has to be crazy, so I will have to break all the English language rules and the basic knowledge of the average human being. I am not an average human being, however I am special. no no no, not THAT kind of special ;). Why do people send that wink face! it always gives me nightmares! it can make a completely normal sentence creepy. imagine you are going to a friend’s house",
      Specialty: "Vascular Surgery",
      Email: "coolsurgeon@yahoo.com",
      Phone: "1234567890",
      PreferredContactMethod: "email",
      image: "image",
    },
    {
      SurgeonName: "Dummy Data4",
      Hospital: "Rancho Santa Margarita",
      Bio: "I am going to do it. I have made up my mind. These are the first few words of the new… the best … the Longest Text In The Entire History Of The Known Universe! This Has To Have Over 35,000 words the beat the current world record set by that person who made that flaming chicken handbooky thingy. I might just be saying random things the whole time I type in this so you might get confused a lot. I just discovered something terrible. autocorrect is on!! no!!! this has to be crazy, so I will have to break all the English language rules and the basic knowledge of the average human being. I am not an average human being, however I am special. no no no, not THAT kind of special ;). Why do people send that wink face! it always gives me nightmares! it can make a completely normal sentence creepy. imagine you are going to a friend’s house",
      Specialty: "Urology Surgery",
      Email: "coolsurgeon@yahoo.com",
      Phone: "1234567890",
      PreferredContactMethod: "email",
      image: "image",
    },
    {
      SurgeonName: "Dummy Data5",
      Hospital: "Rancho Santa Margarita",
      Bio: "I am going to do it. I have made up my mind. These are the first few words of the new… the best … the Longest Text In The Entire History Of The Known Universe! This Has To Have Over 35,000 words the beat the current world record set by that person who made that flaming chicken handbooky thingy. I might just be saying random things the whole time I type in this so you might get confused a lot. I just discovered something terrible. autocorrect is on!! no!!! this has to be crazy, so I will have to break all the English language rules and the basic knowledge of the average human being. I am not an average human being, however I am special. no no no, not THAT kind of special ;). Why do people send that wink face! it always gives me nightmares! it can make a completely normal sentence creepy. imagine you are going to a friend’s house",
      Specialty: "Urology Surgery",
      Email: "coolsurgeon@yahoo.com",
      Phone: "1234567890",
      PreferredContactMethod: "email",
      image: "image",
    },
    {
      SurgeonName: "Dummy Data6",
      Hospital: "Rancho Santa Margarita",
      Bio: "I am going to do it. I have made up my mind. These are the first few words of the new… the best … the Longest Text In The Entire History Of The Known Universe! This Has To Have Over 35,000 words the beat the current world record set by that person who made that flaming chicken handbooky thingy. I might just be saying random things the whole time I type in this so you might get confused a lot. I just discovered something terrible. autocorrect is on!! no!!! this has to be crazy, so I will have to break all the English language rules and the basic knowledge of the average human being. I am not an average human being, however I am special. no no no, not THAT kind of special ;). Why do people send that wink face! it always gives me nightmares! it can make a completely normal sentence creepy. imagine you are going to a friend’s house",
      Specialty: "Urology Surgery",
      Email: "coolsurgeon@yahoo.com",
      Phone: "1234567890",
      PreferredContactMethod: "email",
      image: "image",
    },
    {
      SurgeonName: "Dummy Data7",
      Hospital: "Rancho Santa Margarita",
      Bio: "I am going to do it. I have made up my mind. These are the first few words of the new… the best … the Longest Text In The Entire History Of The Known Universe! This Has To Have Over 35,000 words the beat the current world record set by that person who made that flaming chicken handbooky thingy. I might just be saying random things the whole time I type in this so you might get confused a lot. I just discovered something terrible. autocorrect is on!! no!!! this has to be crazy, so I will have to break all the English language rules and the basic knowledge of the average human being. I am not an average human being, however I am special. no no no, not THAT kind of special ;). Why do people send that wink face! it always gives me nightmares! it can make a completely normal sentence creepy. imagine you are going to a friend’s house",
      Specialty: "Urology Surgery",
      Email: "coolsurgeon@yahoo.com",
      Phone: "1234567890",
      PreferredContactMethod: "email",
      image: "image",
    },
    {
      SurgeonName: "Carl Wheezer",
      Hospital: "Rancho Santa Margarita",
      Bio: "I am going to do it. I have made up my mind. These are the first few words of the new… the best … the Longest Text In The Entire History Of The Known Universe! This Has To Have Over 35,000 words the beat the current world record set by that person who made that flaming chicken handbooky thingy. I might just be saying random things the whole time I type in this so you might get confused a lot. I just discovered something terrible. autocorrect is on!! no!!! this has to be crazy, so I will have to break all the English language rules and the basic knowledge of the average human being. I am not an average human being, however I am special. no no no, not THAT kind of special ;). Why do people send that wink face! it always gives me nightmares! it can make a completely normal sentence creepy. imagine you are going to a friend’s house",
      Specialty: "General",
      Email: "coolsurgeon@yahoo.com",
      Phone: "1234567890",
      PreferredContactMethod: "email",
      image: "image",
    },
    {
      SurgeonName: "Jimmy Neutron",
      Hospital: "Rancho Santa Margarita",
      Bio: "I am going to do it. I have made up my mind. These are the first few words of the new… the best … the Longest Text In The Entire History Of The Known Universe! This Has To Have Over 35,000 words the beat the current world record set by that person who made that flaming chicken handbooky thingy. I might just be saying random things the whole time I type in this so you might get confused a lot. I just discovered something terrible. autocorrect is on!! no!!! this has to be crazy, so I will have to break all the English language rules and the basic knowledge of the average human being. I am not an average human being, however I am special. no no no, not THAT kind of special ;). Why do people send that wink face! it always gives me nightmares! it can make a completely normal sentence creepy. imagine you are going to a friend’s house",
      Specialty: "Breast Surgery",
      Email: "coolsurgeon@yahoo.com",
      Phone: "1234567890",
      PreferredContactMethod: "email",
      image: "image",
    },
  ];
  if (isSearching || useFilteredData || badResponse) {
    if (isSearching) {
      if (searchData.length === 0) {
        return (
          <div styleName="emptyDataContainer" data-cy="empty-list">
            <img
              styleName="emptyDataContainer__img"
              src={NoResultsFound}
              alt="error"
            />
            <span styleName="emptyDataContainer__txt">No results found</span>
            <div styleName="emptyDataContainer_subText">
              Try adjusting your search or filter to find what you’re looking
              for.
            </div>
          </div>
        );
      }
    }
    if (useFilteredData) {
      if (filteredData.length === 0) {
        return (
          <div styleName="emptyDataContainer" data-cy="empty-list">
            <img
              styleName="emptyDataContainer__img"
              src={NoResultsFound}
              alt="error"
            />
            <span styleName="emptyDataContainer__txt">No results found</span>
            <div styleName="emptyDataContainer_subText">
              Try adjusting your search or filter to find what you’re looking
              for.
            </div>
          </div>
        );
      }
    }
    if (badResponse) {
      return (
        <div styleName="emptyDataContainer" data-cy="empty-list">
          <img
            styleName="emptyDataContainer__img"
            src={NoResultsFound}
            alt="error"
          />
          <span styleName="emptyDataContainer__txt">No results found</span>
          <div data-cy="error-text" styleName="emptyDataContainer_subTextError">
            Please contact us
          </div>
        </div>
      );
    }
  }

  const surgeonCard = (index) => {
    const data = isSearching ? searchData : useFilteredData ? filteredData : referenceData; //determine which data set to use depending on boolean variables
    const splitName = data[index].SurgeonName.split(" ");
    var nameNoSpaces = ""
    var initials = ""
    if (splitName.length === 1) {
      initials = splitName[0][0]
      nameNoSpaces = splitName[0]
    } else {
      initials = splitName[0][0] + splitName[1][0];
      nameNoSpaces = splitName[0] + splitName[1];
    }
    return (
      <div
        data-cy="surgeon-card"
        key={index}
        // to = {{pathname: `/${SRPSessionID}/surgeon/${nameNoSpaces.toLowerCase()}`, state: item }}
        styleName="data-card"
        onClick={(e) => {
          handleNavigate(`surgeon/${nameNoSpaces.toLowerCase()}`,{ SurgeonData: data[index], Product: Product, SRPSessionID: SRPSessionID });
        }}
      >
        <div>
          <div styleName="card-upper-container">
            <div styleName="img-container">
              <span>{initials}</span>
            </div>
            <div
              data-cy="surgeon-description"
              styleName="desc-container"
            >
              <h1>{data[index].SurgeonName}</h1>
              <h3>{data[index].Specialty}</h3>
            </div>
          </div>
          <div styleName="card-lower-container">
            <img src={locationIcon} alt="location-icon"></img>
            <span>{data[index].Hospital}</span>
          </div>
          <img
              styleName="card-arrow"
              src={leftArrow}
              alt="left-arrow"
          />
        </div>
      </div>
    );
  }

  const handleNavigate = (location, state) => {
    // console.log({ location, state });
    navigate(location, { state: state });
  };

  const Row = ({index, style}) => {
    return (
      <div style = {style}>
        {
          surgeonCard(index)
        }
      </div>
    );
  }
  // console.log({height})
  return (
    <>
      {isLoading ? (
        <div styleName="loading">
          {/* <FadeLoader loading = {isLoading}/> */}
          <div styleName="loader" />
          {/* <p styleName = 'loading_text'>Loading data . . . .</p> */}
        </div>
      ) : (
        <div styleName="ref-data-container">
          {isSearching
            ? (
              <List 
                height = {height > 264 ? height-200 : height-180}
                itemCount = {searchData.length}
                itemSize = {isMobile? 130 : 120}
                width = {"100%"}
                className="scrollbar"
              >
                {Row}
              </List>
            )   
            : useFilteredData
            ? (
              <List
                height = {height > 264 ? height-200 : height-180}
                itemCount = {filteredData.length}
                itemSize = {isMobile? 130 : 120}
                width = {"100%"}
                className="scrollbar"
              >
                {Row}
              </List>
                
            )
            : (
              <List
                height = {height > 264 ? height-200 : height-180}
                itemCount = {referenceData.length}
                itemSize = {isMobile? 130 : 120}
                width = {"100%"}
                className="scrollbar"
              >
                {Row}
              </List>
            )
          }
        </div>
      )}
    </>
  );
};

ReferenceListData.propTypes = {
  searchTerm: propTypes.string.isRequired,
};
