import React from 'react'
import './SurgeonHeader.scss'
import phoneIcon from '../../SurgeonProfile/ProfileImages/phone.svg'
import emailIcon from '../../SurgeonProfile/ProfileImages/email.svg'
import locationIcon from '../../ReferenceList/ReferenceListImages/location-icon.svg'
export const SurgeonHeader = ({
    surgeonData,
    sendContact
}) => {
    const splitName = surgeonData.SurgeonName.split(' ')
    const initials = splitName[0][0] + splitName[1][0]
    return (
        <div styleName="header-card">
            <div styleName="surgeon-image"><span styleName="initials">{initials}</span></div>
            <div styleName="surgeon-details">
                <h1 styleName = "surgeon-name">{surgeonData.SurgeonName}</h1>
                <h3>{surgeonData.Specialty}</h3>
                <div styleName = "location-container">
                    <img src={locationIcon} alt="location-icon"></img> 
                    <h3>{surgeonData.Hospital}</h3>
                </div>
                <div styleName="preferred-btn-container">
                    <button styleName="preferred-btn" id="preferred-btn"onClick={sendContact} >
                        <img src={surgeonData.PreferredContactMethod === "Email" ? emailIcon : phoneIcon} />
                        {surgeonData.PreferredContactMethod === "Email"? "EMAIL" : "CALL"}
                    </button>
                </div>
            </div>
        </div>
    )
}