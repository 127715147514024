import React from 'react';
import { 
    useNavigate,
    useLocation, 
} from "react-router-dom"; 
import './Navbar.scss'
import logo from './NavbarImages/applied-logo.svg'

export const Navbar = ({
    // sessionID,
    // product
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const SRPSessionID = location.state?.SRPSessionID;
    const product = location.state?.Product;
    console.log({SRPSessionID, product});
    const previousPage = () => {
        // global.location.href === `/${product}/references`;
        const homePage = global.location.origin + "/" + product + "/references";
        console.log({homePage});
        console.log(global.location);
        console.log("location state: ", location.state);
        console.log('global.location.href: ', global.location.href );
        console.log("boolean statement: ", global.location.href === homePage);
        global.location.href === homePage ? navigate(0, {state: {SRPSessionID: SRPSessionID, Product: product}}) : navigate(`/${product}/references`, { state: {SRPSessionID: SRPSessionID, Product: product} } ); //refresh if on landing page already
    }
    return(
        <div styleName="nav-header">
            <div styleName="title-container" onClick = {previousPage}>
                {/* <div styleName="logo-text-container">
                    <h1 styleName="logo-text-upper">Applied</h1>
                    <h3 styleName="logo-text-lower">Medical</h3>
                </div> */}
                <img src={logo} alt="Applied Logo"></img>
            </div>
        </div>
    )
}