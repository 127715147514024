import React, {useEffect, useState} from "react";
import { Navbar } from "./components/Navbar/Navbar.jsx";
import { ReferenceList } from "./components/ReferenceList/ReferenceList.jsx";
import { SurgeonProfile } from "./components/SurgeonProfile/SurgeonProfile.jsx";
import { ExpiredLink } from "./components/ExpiredLink/ExpiredLink.jsx";
import { NoPageFound } from "./components/NoPageFound/NoPageFound.jsx";
import axios from "axios";
import "./App.scss"
import {
  BrowserRouter,
  Route,
  // Switch,
  Routes,
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
export default function App() {
  // const [sessionID, setSessionID] = useState("");
  // const [product, setProduct] = useState("");
  return (
    <>
      <BrowserRouter>
          <Navbar/>
        <Routes>
          {/* <Route path = "Voyant" element = {<Home/>}/> */}
          <Route path=":Product/:SRPSessionID" element = {<Home/>} />
          <Route path=":Product/references" element= {<ReferenceList/>} />
          <Route path=":Product/references/surgeon/:surgeonName" element = {<SurgeonProfile/>} />
          <Route path=":Product/expired" element = {<ExpiredLink/>} />
          <Route path = "*" element = {<NoPageFound/>} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

const Home = ({}) => {
  const navigate = useNavigate();
  // console.log('navigate: ', navigate);
  const location = useLocation();
  console.log( 'location: ',location);
  const {Product, SRPSessionID} = useParams(); //56955a3a-c1af-46fd-9fe4-0c844d27bed2
  //https://decisionsdev2.appliedmed.com/Primary/?FlowAlias=SRPSessionService
  //https://referenceportalqa3.z22.web.core.windows.net/Voyant/56955a3a-c1af-46fd-9fe4-0c844d27bed2 qa3 site
  //console.log("test")
  window.history.replaceState({SRPSessionID: SRPSessionID}, 'Reference Page', '/Voyant'); //to hide SRPSessionId in url
  console.log("SRPSessionID in Home: ", SRPSessionID);
  useEffect(()=>{
    checkSessionId();
  },[]);
  async function checkSessionId(){
    //process.env.NODE_ENV === "production"
    const decisionsEndpoint = global.location.href.includes("references.appliedmedical.com") ? 'https://bpm.appliedmedical.com/Primary/?FlowAlias=SRPSessionService' : 'https://bpmqa.appliedmedical.com/Primary/?FlowAlias=SRPSessionService';
    console.log({decisionsEndpoint});
    console.log({SRPSessionID});
    console.log({Product});
    let response = await axios(decisionsEndpoint, {
        params: {
            sessionid: "NS-2534cfef-2859-11ec-9166-005056bf3f1b",
            outputtype: "Json",
            SRPSessionID: SRPSessionID,
            Action: "api",
        } 
    })
    .then( (response) =>{
      navigate(`/${Product}/references`, { state: {SRPSessionID: SRPSessionID, Product: Product} } )
      console.log(response);
      console.log("SESSION ID IS VALID")
      return response;
    })
    .catch( (err) => {
      navigate(`/${Product}/expired`, {state: {SRPSessionID: SRPSessionID}})
      console.log('SESSION IS NOT VALID')
    })
  };
  //should not start new pipeline immediately because batch is true
  //testing batch again 
  //add 1
  //add 2
  //add 3
  

  return (
    <>
    {
        <div styleName="loading">
            <div styleName="loader"></div>
          <p styleName="loading__text">Validating your session</p>
        </div>
    }
    </>
  )
}