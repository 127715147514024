import { useState, useLayoutEffect, useRef } from "react";
import useInterval from "./useInterval";

export default (sidebarWidth) => {
  const MOBILE_MAX_WIDTH = 769;
  const calculateViewportRefObject = useRef(true);
  const getWidth = () =>
    Math.min(document.documentElement.clientWidth, innerWidth || 0);
  const getHeight = () =>
    Math.max(document.documentElement.clientHeight, innerHeight || 0);
  const [width, setWidth] = useState(getWidth);
  const [height, setHeight] = useState(getHeight);
  const isMobile = width < MOBILE_MAX_WIDTH;
  const setWidthAndHeight = () => {
    const canCalculate = !!(
      isMobile === false && calculateViewportRefObject.current
    );
    // console.log({ canCalculate });
    if (canCalculate) {
      setWidth(getWidth());
      setHeight(getHeight());
    }
  };
  const setCalculateViewport = (bool = true) => {
    calculateViewportRefObject.current = bool;
  };
  useInterval(() => {
    setWidthAndHeight();
  }, 1000);
  return {
    width: sidebarWidth ? width - sidebarWidth : width,
    height,
    isMobile,
    setCalculateViewport: (bool) => {
      // console.log("setting calculateViewport to", bool);
      setCalculateViewport(bool);
    },
  };
};
