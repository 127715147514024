import React from 'react'
import './SurgeonContact.scss'

export const SurgeonContact = ({
    surgeonData,
    phone,
    email
}) => {
    const regex = /[\(]?(\d{0,3})[\) -\.]?(\d{0,3})[ -\.]?(\d{0,4})/;
    const phoneNumberRegex = surgeonData.Phone.match(regex)
    const formattedNumber = `(${phoneNumberRegex[1]}) ${phoneNumberRegex[2]} - ${phoneNumberRegex[3]}`
    return(
        <div styleName="contact-container">
                <div styleName="email-container">
                    <div style={{display: "flex"}}>
                        <h6>EMAIL&nbsp;</h6>
                        <span styleName={surgeonData.PreferredContactMethod === "Email" ? "preferred" : "hidden"}>-&nbsp;Preferred Contact Method</span>
                    </div>
                    <div>
                        <span data-cy="email-contact-link" onClick={()=>
                            {
                                if(window.Cypress){
                                    return
                                }
                                email.current.click();
                            }
                            
                            } styleName="contact-link">{surgeonData.Email}</span>
                    </div>
                </div>
                <div styleName="phone-container">
                    <div style={{display: "flex"}}>
                        <h6>PHONE&nbsp;</h6>
                        <span styleName={surgeonData.PreferredContactMethod === "Phone" ? "preferred" : "hidden"}>-&nbsp;Preferred Contact Method</span>
                    </div>
                    <div>
                        <span data-cy="tel-contact-link"onClick={()=>
                            {
                                if(window.Cypress){
                                    return
                                }
                                phone.current.click()
                            }
                            
                            } styleName="contact-link">{formattedNumber}</span>
                    </div>
                </div>                
        </div>
    )
}