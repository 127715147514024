import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router'
import './SurgeonProfile.scss'
import phoneIcon from '../SurgeonProfile/ProfileImages/phone.svg'
import emailIcon from '../SurgeonProfile/ProfileImages/email.svg'
import backArrow from './ProfileImages/back-arrow.svg'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { SurgeonHeader } from './SurgeonHeader/SurgeonHeader.jsx'
import { SurgeonAbout } from './SurgeonAbout/SurgeonAbout.jsx'
import { SurgeonContact } from './SurgeonContact/SurgeonContact.jsx'

export const SurgeonProfile = () => {
    const email = useRef(null)
    const phone = useRef(null)
    const location = useLocation();
    const SRPSessionID = location.state.SRPSessionID;
    const Product = location.state.SRPSessionID;
    const surgeonData = location.state.SurgeonData;
    console.log({SRPSessionID, Product});
    const navigate = useNavigate();
    
    console.log({location});
    console.log({surgeonData});
    const sendContact = () => {
        if(surgeonData.PreferredContactMethod === "Email"){
            email.current.click()
        } else {
            phone.current.click()
        }
    }
    const regex = /[\(]?(\d{0,3})[\) -\.]?(\d{0,3})[ -\.]?(\d{0,4})/;
    const phoneNumberRegex = surgeonData.Phone.match(regex)
    const callNumber = `${phoneNumberRegex[1]}-${phoneNumberRegex[2]}-${phoneNumberRegex[3]}`

    useEffect(()=>{
        // window.history.replaceState({SRPSessionID: SRPSessionID}, 'Surgeon Profile', `/${location.pathname.slice(location.pathname.indexOf('surgeon'))}`); //to hide SRPSessionId in url
        // console.log('pathname: ', location.pathname.slice(location.pathname.indexOf('surgeon')));
        window.scrollTo(top)
    },[]);


    return(
        <>
            <div styleName="back-container">
                <div 
                    data-cy = "back-button" 
                    to={`/${SRPSessionID}/references`} 
                    styleName="link"
                    onClick = { (e) => {
                        navigate(`/${Product}/references`, { state: {SRPSessionID: SRPSessionID, Product: Product} } )
                    } }
                >
                    <img src={backArrow} alt="back-arrow"/>
                    <h3 styleName="reference-route-title">Voyant Reference Portal</h3>
                </div>
            </div>
            <div styleName="card">
                <SurgeonHeader surgeonData={surgeonData} sendContact={sendContact}></SurgeonHeader>
                <SurgeonAbout surgeonData={surgeonData}></SurgeonAbout>
                <SurgeonContact surgeonData={surgeonData} email={email} phone={phone}></SurgeonContact>
                <div styleName="preferred-btn-container">
                    <button styleName="preferred-btn" id="preferred-btn" onClick={sendContact}>
                    <img src={surgeonData.PreferredContactMethod === "Email"? emailIcon : phoneIcon} />
                        {surgeonData.PreferredContactMethod === "Email"? "EMAIL" : "CALL"}
                    </button>
                </div>
                <a data-cy = "surgeon-email" href = {`mailto: ${surgeonData.Email}`} ref={email} styleName="contact">EMAIL</a> 
                <a data-cy = "surgeon-phone" href = {`tel:${callNumber}`} ref={phone} styleName="contact">PHONE</a>
            </div>
        </>
    )
}