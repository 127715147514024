import React, {useState} from 'react';
import { ExpiredCard } from './ExpiredCard/ExpiredCard.jsx'
import './ExpiredLink.scss'
export const ExpiredLink = () => {

    return(
        <>
            <ExpiredCard/>
            {/* <div styleName="expired-link-container">
                <span>
                    If this issue continues to persist,
                </span>
                <span>
                    let us know.
                </span>
            </div> */}
        </>
    )
}