import React, {useEffect, useRef, useState} from 'react';
import './ReferenceListSearch.scss';
import searchGlass from '../ReferenceListImages/search-glass.svg';
import filterIcon from '../ReferenceListImages/filter-icon.svg';
import { ReferenceModal } from './ReferenceModal/ReferenceModal.jsx';
import propTypes from 'prop-types';

export const ReferenceListSearch = ({
    searchTerm, 
    setSearchTerm,
    filters,
    setFilters,
    applyFilter,
    setApplyFilter,
    useFilteredData,
    setUseFilteredData,
    }) => {
    const [activeFilter, setActiveFilter] = useState(false)
    const formRef = useRef(null)
    
    /**
     * closes filter modal
     */
    useEffect(()=>{
        if(activeFilter){
            window.addEventListener('click', closeModal)
        }
        return () => {window.removeEventListener('click',closeModal)}
    },[activeFilter])

    // console.log(filters)
    /**
     * used to close filter modal
     * @param {*} e event
     */
    const closeModal = (e) => {
        e.stopPropagation()
        console.log(e.target.alt)
        console.log(e.target.innerText.toLowerCase())
        // if(e.target.alt !== "mobile close button"){
        //     console.log('not mobile close')
        //     setActiveFilter(false)
        //     return
        // }
        if(e.target.innerText.toLowerCase() === "cancel" || e.target.alt === "mobile close button") {
            console.log("close filters")
            const listItem = formRef.current.children[0].children
            // for(let i = 0; i < listItem.length; i++){
            //     let inputBox = listItem[i].firstChild
            //     inputBox.checked = false
            // }
            const listItemArray = [...listItem];
            listItemArray.forEach( (el, index) => {
                let inputBox = el.firstChild;
                inputBox.checked = false;
            } );
            formRef.current.reset()
            setFilters([])
            setApplyFilter(false); //for filter button
            setUseFilteredData(false);
            setActiveFilter(false)
        } else {
            setActiveFilter(false)
            return
        }

    }

    /**
     * used to set whether filters are active or not
     * @param {*} e event
     */
    const filterClick = (e) => {
        setActiveFilter(!activeFilter)
    }

    /**
     * changes search term as input changes in search bar
     * @param {*} event search bar input
     */
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    }

    return(
        <>
            <div styleName="search-container">
                <input 
                    data-cy = "search-bar"
                    type="search" 
                    styleName="search-input"
                    placeholder="Search speciality, hospital, or name"
                    value = {searchTerm}
                    onChange = {handleSearchChange}
                />
                <img src={searchGlass} alt="searchGlass" styleName="search-glass"></img>
                <div
                    data-cy = "filter-button"
                    styleName={activeFilter ? "active-filter-container" : "filter-container"} 
                    onClick={filterClick} 
                    id="filter-container"
                >
                    <img src={filterIcon} alt="filterIcon" styleName="filter-btn-icon"></img>
                    <span>Filter</span>
                    <div styleName={filters.length > 0 ? "number-of-filters" : "hidden"} data-cy="filter-count">
                        {filters.length - 1 + 1}
                    </div>
                    <ReferenceModal 
                        filters={filters} 
                        setFilters={setFilters}
                        setActiveFilter={setActiveFilter} 
                        closeModal={closeModal} 
                        activeFilter={activeFilter} 
                        formRef={formRef}
                        applyFilter = {applyFilter}
                        setApplyFilter = {setApplyFilter}
                        useFilteredData = {useFilteredData}
                        setUseFilteredData = {setUseFilteredData}
                    />
                </div>
            </div>
        </>
    )
};

ReferenceListSearch.propTypes = {
    searchTerm: propTypes.string.isRequired,
    setSearchTerm: propTypes.func.isRequired,
};

