import React, {useState, useEffect} from "react";
import './ReferenceModal.scss'
import filterIcon from '../../ReferenceListImages/filter-icon.svg'
import closeX from '../../ReferenceListImages/close-x.svg'
export const ReferenceModal = ({
    filters, 
    setFilters, 
    setActiveFilter, 
    closeModal, 
    activeFilter, 
    formRef,
    applyFilter,
    setApplyFilter,
    useFilteredData,
    setUseFilteredData,
}) => {
    const [hover, setHover] = useState(false)
    const [checked, setChecked] = useState(false)
    /**
     * Used to get specialties to use in filter button
     * @returns jsx element of unordered list of specialties
     */
    const getSpecialties = () => {
        const specialties = ["Bariatric", "Breast", "Colorectal", "ENT", "General", "Hepatobiliary", "Hospital Administration", "OB/GYN", "Oncology", "Pediatric", "Thoracic", "Transplant", "Trauma", "Urology", "Vascular"];
        return (
            <ul data-cy="specialty-list" styleName = "speciality-list">
                {specialties.map( (el, index) => {
                    return(
                        <li styleName='checkbox-container' key = {index}>
                            <input styleName='checkbox-input' type="checkbox" id={`speciality-${index+1}`} name={`speciality-${index+1}`} onChange={handleCheck}/>
                            <label styleName='checkbox-label' htmlFor={`speciality-${index+1}`} data-cy = {`specialty-filter-${el}`} 
                            onMouseOver={handleHover}>{el}</label>
                        </li>
                    )
                } )}
            </ul>
        )
    }
    /**
     * applies the check mark when a specialty is selected
     * @param {*} e event
     */ 
    const handleHover = (e) => {
        const inputBox = e.target.parentNode.firstChild.checked
        if(inputBox){
            e.target.parentNode.firstChild.style.opacity = "0"
        } else {
            e.target.parentNode.firstChild.style.opacity = "1"
        }
    }
    const handleCheck = (e) => {
        if(e.target.checked === true){
            setFilters([...filters, e.target.nextSibling.innerText])
            e.target.parentNode.firstChild.style.opacity = "0"
        } else {
            var filtered = filters.filter(item=>{
                return item !== e.target.nextSibling.innerText
            })
            setFilters(filtered)
        }
    }

    /**
     * function that triggers filters to be applied to data once 'apply filters' button is pressed
     */
    const applyFilters = () => {
        setApplyFilter(true); //used in referenceData
        setUseFilteredData(true); //used in referenceData
        setActiveFilter(false) //used in reference list search
    }

    return (
        <>
        <div data-cy = 'filter-modal' styleName={activeFilter ? "overlay" : "overlay-hidden"} onClick={closeModal}></div>
            <div styleName={activeFilter ? "ref-modal" : "ref-modal-hidden"} id="modal" onClick={e=>e.stopPropagation()}>
                <div styleName="title-container">
                    <img styleName="filter-modal-icon" src={filterIcon} alt="filter-icon"/>
                    <h3 data-cy = "filter-title" styleName="filter-title">Filter</h3>
                    <img src={closeX} alt="mobile close button" onClick={closeModal} styleName="close-x"></img>
                </div>
                <div data-cy = "specialties-title" styleName="speciality-container">
                    <h3  styleName="speciality-title">Specialties</h3>
                    <div>
                        <form ref={formRef}>
                            {getSpecialties()}
                        </form>
                    </div>
                </div>
                <div styleName="exit-container">
                    <h3 styleName="close-modal" data-cy = "cancel-btn" onClick={closeModal}>Cancel</h3>
                    <button styleName="apply-btn" data-cy = "apply-btn" onClick={applyFilters}>Apply Filters</button>
                </div>
        </div>
        </>
    )
}