import React, { useEffect, useRef, useState } from 'react'
import downArrow from '../ProfileImages/down-arrow.svg'
import './SurgeonAbout.scss'


export const SurgeonAbout = ({surgeonData}) => {
    const [overflow, setOverflow] = useState(false)
    const [overflowBtn, setOverFlowBtn] = useState(false)
    const paragraphRef = useRef(null)
    useEffect(()=>{
        console.log("running")
        let paragraphHeight = (paragraphRef.current.offsetHeight)
        console.log(paragraphHeight)
        if(paragraphHeight >= 80){
            setOverflow(true)
        }

    },[])
    // console.log(paragraphRef.current.offsetHeight)
    console.log(overflow)
    const showOverflow = (e) => {
        // setOverflow(!overflow)
        setOverFlowBtn(!overflowBtn)
    }
    return (
        <>
        { window.innerWidth < 768 ? 
                (
                    overflow ? 
                    (
                        <div styleName="about-container" id="about-container" onClick={showOverflow} >
                            <p data-cy = 'surgeon-profile-description'  styleName={overflowBtn ? "surgeon-desc" : "surgeon-desc-hidden"}  ref={paragraphRef} id="desc" id= "about">
                                {surgeonData.Bio}
                            </p>
                            <div styleName = "overflow">
                                <img styleName = {overflowBtn ? 'up-arrow' : 'down-arrow'} src = {downArrow} alt='down-arrow'/>
                            </div>
                        </div>
                    )
                    :
                    (
                        <div styleName="about-container" id="about-container" >
                            <p data-cy = 'surgeon-profile-description' styleName= "surgeon-desc" id="desc" id= "about" ref={paragraphRef}>
                                {surgeonData.Bio}
                            </p>
                        </div>
                    )
                )
        : 
        (
            <div styleName="about-container" id="about-container" >
                <p data-cy = 'surgeon-profile-description' styleName= "surgeon-desc" ref={paragraphRef} id="desc" id= "about">
                    {surgeonData.Bio}
                </p>
            </div>
        )
            
        }
        {/* {overflow ?
        (
            <div styleName="about-container" id="about-container" onClick={showOverflow} >
                <p data-cy = 'surgeon-profile-description' styleName={overflow ? "surgeon-desc-hidden" : "surgeon-desc"} ref={paragraphRef} id="desc" id= "about">
                    {surgeonData.Bio}
                </p>
                <div styleName = "overflow">
                    <img styleName = {!overflowBtn ? 'down-arrow' : 'up-arrow'} src = {downArrow} alt='down-arrow'/>
                </div>
            </div>
        )
        :
        (
            <div styleName="about-container" id="about-container" >
                <p data-cy = 'surgeon-profile-description' styleName= "surgeon-desc" ref={paragraphRef} id="desc" id= "about">
                    {surgeonData.Bio}
                </p>
            </div>
        )
        } */}
        </>
    )
}